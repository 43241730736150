<template>
  <div>
    <a-month-picker
      placeholder="选择年月"
      allowClear
      @change="changeMonth"
      :defaultValue="defaultValue"
    />
    <!-- {{ data.calendar }} -->
    <div class="date_box">
      <!-- 星期 -->
      <div class="week">
        <span class="w-item" v-for="(item, index) in week" :key="index">{{
          item
        }}</span>
      </div>
      <!-- 日期 -->
      <div class="day">
        <div class="day_time" v-for="(item, index) in calendar" :key="index">
          <div
            v-for="subItem in item"
            :key="subItem.attendanceDate"
            class="num"
          >
            <p class="c-date" :class="{ 'is-current-month': !subItem.isShow }">
              {{ subItem.date }}
            </p>
            <p class="c-text" v-if="subItem.isShow && subItem.content > 0">
              出勤{{ subItem.content }}小时
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getWorkerAttendanceCalendar } from '@/services/screen/jiangsu/labor';
import dayjs from 'dayjs';
import { chunk } from 'lodash';
import { formatHours } from '@/views/screen/utils';
@Component({})
export default class WorkTimeCalendar extends Vue {
  @Prop({ type: Object, default: () => {} }) data;
  @Prop({ type: String, default: '' }) projectId;
  created() {
    this.init();
  }
  calendar = [];
  week = ['日', '一', '二', '三', '四', '五', '六'];
  init() {
    //处理日期
    this.getWorkerAttendanceCalendarData(this.defaultValue);
  }
  month = this.dayjs().month();
  year = this.dayjs().year();
  get calendarParams() {
    return {
      dataId: this.data.dataId,
      month: this.month + 1,
      year: this.year,
      projectId: this.projectId,
    };
  }
  cData = [];
  async getWorkerAttendanceCalendarData(dateValue) {
    const res = await getWorkerAttendanceCalendar(this.calendarParams);
    // window.console.log(res, 'getWorkerAttendanceCalendarData');
    const realDate =
      res.map(ele => ({
        attendanceDate: this.dayjs(ele.attendanceDate).format('YYYY-MM-DD'),
        workingTime: ele.workingTime,
      })) || [];

    const defaultDate = this.getMonth(dateValue);
    defaultDate.forEach(ele => {
      realDate.forEach(sEle => {
        if (ele.time === sEle.attendanceDate) {
          ele.content = formatHours(sEle.workingTime, true);
        }
      });
    });
    // window.console.log(realDate, 'realDate');
    // window.console.log(defaultDate, 'defaultDate');
    this.calendar = chunk(defaultDate, 7);
  }
  getMonth(e) {
    const startDay = dayjs(e).startOf('month');
    const endDay = dayjs(e).endOf('month');
    let currentDay = startDay;
    let list = [];
    while (currentDay.isBefore(endDay)) {
      list.push({ day: currentDay, isShow: true });
      currentDay = currentDay.add(1, 'day');
    }
    //window.console.log(list, 'list');
    let week = list[0].day.day();
    if (week === 0) {
      week = 7;
    }

    for (let i = 1; i < week; i++) {
      // ishow:区分是否是本月日期
      list.unshift({ day: startDay.add(-i, 'day'), isShow: false });
    }
    //下个月补充   42:日历中的六周 * 一周七天
    const nextWeek = 42 - (dayjs(e).daysInMonth() + week - 1);
    for (let i = 1; i <= nextWeek; i++) {
      // ishow:区分是否是本月日期
      list.push({ day: endDay.add(i, 'day'), isMonth: false });
    }
    list = list.map(item => {
      const str = item.day.$d.toLocaleDateString().replaceAll('/', '-');
      item.time = dayjs(str).format('YYYY-MM-DD');
      item.date = dayjs(str).date();
      // item.content = `出勤了${index}小时`;
      return item;
    });
    // console.log("本月天数",list)
    return list;
  }
  get defaultValue() {
    return this.dayjs().format('YYYY-MM-DD');
  }
  curentValue = '';
  changeMonth(value) {
    // window.console.log('month', this.dayjs(value).month());
    // window.console.log('year', this.dayjs(value).year());
    this.year = this.dayjs(value).year();
    this.month = this.dayjs(value).month();
    const dateValue = this.dayjs(value).format('YYYY-MM-DD');
    // this.calendar = chunk(this.getMonth(`${year}-${month + 1}`), 7);
    this.getWorkerAttendanceCalendarData(dateValue);
  }
}
</script>
<style lang="less" scoped>
.date_box {
  padding: 0.32rem 0;
  .week {
    display: flex;
    align-items: center;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    span {
      flex: 1;
      // border: 1px solid #ccc;
      // border-bottom: none;
      height: 0.6rem;
    }
  }
  .day {
    width: 90%;
    margin: 0 auto 24px;
    // background: green;
    .day_time {
      display: flex;
      align-items: center;
      justify-content: space-around;
      text-align: center;
      box-sizing: border-box;
      .num {
        // border: 1px solid #ccc;
        // width: 0.98rem;
        flex: 1;
        height: 0.8rem;
        .is-current-month {
          color: #4a4a4a;
        }
        .c-date,
        .c-text {
          padding: 0;
          margin: 0;
        }
        .c-text {
          font-size: 0.14rem;
          font-family: Microsoft YaHei UI-Regular;
          font-weight: 400;
          color: #37d7db;
        }
      }
    }
  }
}
</style>
