import { request, serviceName } from './base-config';

// 工人基本信息
export function getLaborBasicInfo(data) {
  return request(`${serviceName}/screen/labor/worker/attendance/count`, {
    method: 'GET',
    body: data,
  });
}

// 工种在场状态
export function getWorkType(data) {
  return request(`${serviceName}/screen/labor/work/type/count`, {
    method: 'GET',
    body: data,
  });
}

// 单位考勤状态
export function getCompanyAttendance(data) {
  return request(`${serviceName}/screen/labor/company/count`, {
    method: 'GET',
    body: data,
  });
}

// 安管在岗时间
export function getLaborSafeTime(data) {
  return request(`${serviceName}/screen/labor/safe/time/count`, {
    method: 'GET',
    body: data,
  });
}

// 班组信息
export function getLaborTeamInfo(data) {
  return request(`${serviceName}/screen/labor/team`, {
    method: 'GET',
    body: data,
  });
}

// 公司信息
export function getUnitInfo(data) {
  return request(`${serviceName}/screen/labor/company`, {
    method: 'GET',
    body: data,
  });
}

// 人员信息
export function getLaborDetail(data) {
  return request(`${serviceName}/screen/labor/worker`, {
    method: 'POST',
    body: data,
  });
}

// 工人良好行为
export function getLaborGoodBehavior(data) {
  return request(`${serviceName}/screen/labor/worker/behavior`, {
    method: 'GET',
    body: data,
  });
}

// 工人不良行为
export function getLaborBadBehavior(data) {
  return request(`${serviceName}/screen/labor/worker/bad/behavior`, {
    method: 'GET',
    body: data,
  });
}

// 工人惩罚分析
export function getWorkerPublish(data) {
  return request(`${serviceName}/screen/labor/worker/rp/count`, {
    method: 'GET',
    body: data,
  });
}

// 工人奖惩记录
export function getWorkerPublishRecords(data) {
  return request(`${serviceName}/screen/labor/worker/rp`, {
    method: 'GET',
    body: data,
  });
}

// 人员属性分析（年龄）
export function getWorkerLaborAge(data) {
  return request(`${serviceName}/screen/labor/worker/age/count`, {
    method: 'GET',
    body: data,
  });
}

// 人员属性分析（籍贯）
export function getWorkerLaborPlace(data) {
  return request(`${serviceName}/screen/labor/worker/place/count`, {
    method: 'GET',
    body: data,
  });
}

// 安全教育分析
export function getWorkerSafeEducation(data) {
  return request(`${serviceName}/screen/labor/safe/education/count`, {
    method: 'GET',
    body: data,
  });
}

// 出勤数据
export function getLabelAccessRecord(data) {
  return request(`${serviceName}/screen/labor/access`, {
    method: 'POST',
    body: data,
  });
}

// 出勤数据（趋势）
export function getLabelAttendanceTrend(data) {
  return request(`${serviceName}/screen/labor/attendance/trend`, {
    method: 'GET',
    body: data,
  });
}

// 岗位列表
export function getJobPosition(data) {
  return request(`${serviceName}/screen/labor/jobPosition`, {
    method: 'GET',
    body: data,
  });
}

// 工种列表
export function getJobType(data) {
  return request(`${serviceName}/screen/labor/jobType`, {
    method: 'GET',
    body: data,
  });
}

// 出勤数据（出入记录）
export function getLastLaborAccess(data) {
  return request(`${serviceName}/screen/labor/access`, {
    method: 'POST',
    body: data,
  });
}

// 人员扩展信息查询
export function getWorkerExtend(data) {
  return request(`${serviceName}/screen/labor/worker/extend`, {
    method: 'GET',
    body: data,
  });
}

// 工人考勤日历
export function getWorkerAttendanceCalendar(data) {
  return request(`${serviceName}/screen/labor/worker/attendance/calendar`, {
    method: 'GET',
    body: data,
  });
}

// 安全教育记录  smart-site/jiangsu/screen/labor/safe/education
export function getWorkerSafeEducationRecords(data) {
  return request(`${serviceName}/screen/labor/safe/education`, {
    method: 'GET',
    body: data,
  });
}

// 人员信息查询  get
export function getLaborDetailById(data) {
  return request(`${serviceName}/screen/labor/worker`, {
    method: 'GET',
    body: data,
  });
}

// 获取设备信息
export function getDeviceListsByCode(data) {
  return request(`/triascloud-smart-site/jiangsu/screen/common/device`, {
    method: 'GET',
    body: data,
  });
}
