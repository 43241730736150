<template>
  <div :class="$style['spot-detail-container']">
    <WorkerTitle title="信息记录" />
    <div
      :class="$style['spot-detail-workerInfo']"
      v-if="dialogType === 'danger'"
    >
      <!-- 隐患单号、上报人员、检查时间、隐患状态、项目名称、隐患描述、隐患照片、隐患位置、是否隐患、隐患级别、施工单位、整改负责人、整改期限、整改内容 -->
      <ul
        :class="[
          $style['spot-detail-infoLists'],
          $style['spot-detail-infoLists-first'],
        ]"
      >
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>隐患单号</h3>
          <p>{{ data.number }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>上报人员</h3>
          <p>{{ data.fullName }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>上报人手机号</h3>
          <p>{{ data.phone }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查时间</h3>
          <p>{{ formatTime(data.dangerTime) }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>隐患状态</h3>
          <p>{{ data.dangerState }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>隐患类型</h3>
          <p>{{ data.dangerType || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>项目名称</h3>
          <p>{{ data.projectName }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>项目编号</h3>
          <p>{{ data.projectNumber }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>隐患位置</h3>
          <p>{{ data.dangerLocation }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>是否隐患</h3>
          <p>{{ data.isDanger }}</p>
        </li>

        <li :class="$style['spot-detail-infoLists-item']">
          <h3>隐患级别</h3>
          <p>{{ data.dangerLevel }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>施工单位</h3>
          <p>{{ data.companyName }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>整改负责人</h3>
          <p>{{ data.rectifierName }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>整改期限</h3>
          <p>{{ formatTime(data.rectificationDeadline) }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>整改内容</h3>
          <p>{{ data.rectificationContent }}</p>
        </li>
      </ul>
      <ul :class="$style['spot-detail-infoLists']">
        <li
          :class="[
            $style['spot-detail-infoLists-item'],
            $style['spot-detail-infoLists-desc'],
          ]"
        >
          <h3>隐患描述</h3>
          <p>
            {{ data.dangerContent || '-' }}
          </p>
        </li>
      </ul>
      <ul :class="$style['spot-detail-infoLists']">
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>隐患照片</h3>
          <div :class="$style['spot-detail-infoLists-infoImg']">
            <div v-if="pics1.length">
              <x-oss-image
                v-for="(item, index) in pics1"
                :key="index"
                basePath="/oss/oss"
                :ossPath="item.value || ''"
                @click="
                  openImg(
                    pics1.map(ele => ele.value),
                    index,
                  )
                "
                class="table-img"
              />
            </div>
            <div v-else style="width: 30px;">
              <NoImg />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      :class="$style['spot-detail-workerInfo']"
      v-if="dialogType === 'inspect'"
    >
      <!-- 巡检编号、巡检人、项目名称、检查标准、巡检日期、巡检点、巡检内容、巡检照片、巡检结果、巡检状态、施工单位、整改负责人、整改期限、整改内容 -->
      <ul
        :class="[
          $style['spot-detail-infoLists'],
          $style['spot-detail-infoLists-first'],
        ]"
      >
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>巡检编号</h3>
          <p>{{ data.number || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>项目名称</h3>
          <p>{{ data.projectName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>巡检人</h3>
          <p>{{ data.fullName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查标准</h3>
          <p>{{ data.inspectionStandard || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>巡检日期</h3>
          <p>{{ formatTime(data.inspectionTime) || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>巡检点</h3>
          <p>{{ data.inspectionLocation || '-' }}</p>
        </li>

        <li :class="$style['spot-detail-infoLists-item']">
          <h3>巡检结果</h3>
          <p>{{ data.inspectionResult || '-' }}</p>
        </li>
        <li
          :class="$style['spot-detail-infoLists-item']"
          v-if="data.inspectionResult !== '巡检正常'"
        >
          <h3>隐患类型</h3>
          <p>{{ data.dangerType || '-' }}</p>
        </li>
        <li
          :class="$style['spot-detail-infoLists-item']"
          v-if="data.inspectionResult === '巡检异常'"
        >
          <h3>巡检状态</h3>
          <p>{{ data.inspectionState || '-' }}</p>
        </li>

        <li :class="$style['spot-detail-infoLists-item']">
          <h3>施工单位</h3>
          <p>{{ data.companyName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>整改负责人</h3>
          <p>{{ data.rectifierName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>整改期限</h3>
          <p>{{ formatTime(data.rectificationDeadline) || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>整改内容</h3>

          <p>{{ data.rectificationContent || '-' }}</p>
        </li>
      </ul>
      <ul :class="$style['spot-detail-infoLists']">
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>巡检内容</h3>
          <p>{{ data.inspectionContent || '-' }}</p>
        </li>
      </ul>
      <ul :class="$style['spot-detail-infoLists']">
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>巡检照片</h3>
          <div :class="$style['spot-detail-infoLists-infoImg']">
            <div v-if="pics1.length">
              <x-oss-image
                v-for="(item, index) in pics1"
                :key="index"
                basePath="/oss/oss"
                :ossPath="item.value || ''"
                @click="
                  openImg(
                    pics1.map(ele => ele.value),
                    index,
                  )
                "
                class="table-img"
              />
            </div>
            <div v-else style="width: 30px;">
              <NoImg />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div :class="$style['spot-detail-workerInfo']" v-if="dialogType === 'safe'">
      <!-- 检查编号、检查日期、检查人、项目名称、检查标准、检查单类型、检查状态、隐患等级、检查类别、检查部位、隐患类型、检查内容、检查照片、施工单位、整改负责人、整改期限、整改内容 -->
      <ul
        :class="[
          $style['spot-detail-infoLists'],
          $style['spot-detail-infoLists-first'],
        ]"
      >
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查编号</h3>
          <p>{{ data.number || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>项目名称</h3>
          <p>{{ data.projectName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查人</h3>
          <p>{{ data.fullName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查标准</h3>
          <p>{{ data.inspectionStandard || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查日期</h3>
          <p>{{ formatTime(data.inspectionTime) || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查单类型</h3>
          <p>{{ data.checklistType || '-' }}</p>
        </li>
        <li
          :class="$style['spot-detail-infoLists-item']"
          v-if="['隐患整改', '停工整改'].includes(data.checklistType)"
        >
          <h3>检查状态</h3>
          <p>{{ data.inspectionState || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>隐患等级</h3>
          <p>{{ data.dangerLevel || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查类别</h3>
          <p>{{ data.inspectionType || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查部位</h3>
          <p>{{ data.inspectionLocation || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>隐患类型</h3>
          <p>{{ data.dangerType || '-' }}</p>
        </li>

        <li :class="$style['spot-detail-infoLists-item']">
          <h3>施工单位</h3>
          <p>{{ data.companyName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>整改负责人</h3>
          <p>{{ data.rectifierName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>整改期限</h3>
          <p>{{ formatTime(data.rectificationDeadline) || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>整改内容</h3>
          <p>{{ data.rectificationContent || '-' }}</p>
        </li>
      </ul>
      <ul :class="$style['spot-detail-infoLists']">
        <li
          :class="[
            $style['spot-detail-infoLists-item'],
            $style['spot-detail-infoLists-desc'],
          ]"
        >
          <h3>检查内容</h3>
          <p>
            {{ data.inspectionContent || '-' }}
          </p>
        </li>
      </ul>
      <ul :class="$style['spot-detail-infoLists']">
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查照片</h3>
          <div :class="$style['spot-detail-infoLists-infoImg']">
            <div v-if="pics1.length">
              <x-oss-image
                v-for="(item, index) in pics1"
                :key="index"
                basePath="/oss/oss"
                :ossPath="item.value || ''"
                @click="
                  openImg(
                    pics1.map(ele => ele.value),
                    index,
                  )
                "
                class="table-img"
              />
            </div>
            <div v-else style="width: 30px;">
              <NoImg />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      :class="$style['spot-detail-workerInfo']"
      v-if="dialogType === 'company'"
    >
      <!-- 检查编号、检查日期、检查人、检查状态、项目名称、项目编号、
      检查单类型、单位名称、检查类别、检查照片、整改要求、整改负责人、整改期限 -->
      <ul
        :class="[
          $style['spot-detail-infoLists'],
          $style['spot-detail-infoLists-first'],
        ]"
      >
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查编号</h3>
          <p>{{ data.number || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查人</h3>
          <p>{{ data.fullName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>项目名称</h3>
          <p>{{ data.projectName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>项目编号</h3>
          <p>{{ data.projectNumber || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查日期</h3>
          <p>{{ formatTime(data.inspectionTime) || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查单类型</h3>
          <p>{{ data.checklistType || '-' }}</p>
        </li>
        <li
          :class="$style['spot-detail-infoLists-item']"
          v-if="data.checklistType !== '一般记录'"
        >
          <h3>隐患类型</h3>
          <p>{{ data.checklistType || '-' }}</p>
        </li>
        <li
          :class="$style['spot-detail-infoLists-item']"
          v-if="['隐患整改', '停工整改'].includes(data.checklistType)"
        >
          <h3>检查状态</h3>
          <p>{{ data.inspectionState || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>单位名称</h3>
          <p>{{ data.companyName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查类别</h3>
          <p>{{ data.inspectionType || '-' }}</p>
        </li>

        <li :class="$style['spot-detail-infoLists-item']">
          <h3>整改负责人</h3>
          <p>{{ data.rectifierName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>整改期限</h3>
          <p>{{ formatTime(data.rectificationDeadline) || '-' }}</p>
        </li>
      </ul>
      <ul :class="$style['spot-detail-infoLists']">
        <li
          :class="[
            $style['spot-detail-infoLists-item'],
            $style['spot-detail-infoLists-desc'],
          ]"
        >
          <h3>整改内容</h3>
          <p>
            {{ data.rectificationContent || '-' }}
          </p>
        </li>
      </ul>
      <ul :class="$style['spot-detail-infoLists']">
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查照片</h3>
          <div :class="$style['spot-detail-infoLists-infoImg']">
            <div v-if="pics1.length">
              <x-oss-image
                v-for="(item, index) in pics1"
                :key="index"
                basePath="/oss/oss"
                :ossPath="item.value || ''"
                @click="
                  openImg(
                    pics1.map(ele => ele.value),
                    index,
                  )
                "
                class="table-img"
              />
            </div>
            <div v-else style="width: 30px;">
              <NoImg />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      :class="$style['spot-detail-workerInfo']"
      v-if="dialogType === 'project'"
    >
      <!-- 检查编号、检查日期、检查人、项目名称、检查标准、检查单类型、
      检查状态、隐患等级、检查类别、检查部位、隐患类型、检查内容、检查照片、施工单位、整改负责人、整改期限、整改内容 -->
      <ul
        :class="[
          $style['spot-detail-infoLists'],
          $style['spot-detail-infoLists-first'],
        ]"
      >
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查编号</h3>
          <p>{{ data.number || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>项目名称</h3>
          <p>{{ data.projectName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查人</h3>
          <p>{{ data.fullName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查日期</h3>
          <p>{{ formatTime(data.inspectionTime) || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查单类型</h3>
          <p>{{ data.checklistType || '-' }}</p>
        </li>
        <li
          :class="$style['spot-detail-infoLists-item']"
          v-if="data.checklistType !== '一般记录'"
        >
          <h3>隐患类型</h3>
          <p>{{ data.dangerType || '-' }}</p>
        </li>
        <li
          :class="$style['spot-detail-infoLists-item']"
          v-if="data.checklistType !== '一般记录'"
        >
          <h3>隐患等级</h3>
          <p>{{ data.dangerLevel || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查类别</h3>
          <p>{{ data.inspectionType || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查部位</h3>
          <p>{{ data.inspectionLocation || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查内容</h3>
          <p>{{ data.inspectionContent || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查状态</h3>
          <p>{{ data.inspectionState || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>施工单位</h3>
          <p>{{ data.companyName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>整改负责人</h3>
          <p>{{ data.rectifierName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>整改期限</h3>
          <p>{{ formatTime(data.rectificationDeadline) || '-' }}</p>
        </li>
      </ul>
      <ul :class="$style['spot-detail-infoLists']">
        <li
          :class="[
            $style['spot-detail-infoLists-item'],
            $style['spot-detail-infoLists-desc'],
          ]"
        >
          <h3>整改内容</h3>
          <p>
            {{ data.rectificationContent || '-' }}
          </p>
        </li>
      </ul>
      <ul :class="$style['spot-detail-infoLists']">
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查照片</h3>
          <div :class="$style['spot-detail-infoLists-infoImg']">
            <div v-if="pics1.length">
              <x-oss-image
                v-for="(item, index) in pics1"
                :key="index"
                basePath="/oss/oss"
                :ossPath="item.value || ''"
                @click="
                  openImg(
                    pics1.map(ele => ele.value),
                    index,
                  )
                "
                class="table-img"
              />
            </div>
            <div v-else style="width: 30px;">
              <NoImg />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      :class="$style['spot-detail-workerInfo']"
      v-if="dialogType === 'watch'"
    >
      <!-- 检查编号、检查日期、检查人、检查状态、项目名称、项目编号、
      检查单类型、单位名称、所属科室、开单对象、检查照片、整改要求、整改负责人、整改期限 -->
      <ul
        :class="[
          $style['spot-detail-infoLists'],
          $style['spot-detail-infoLists-first'],
        ]"
      >
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查编号</h3>
          <p>{{ data.number || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查人</h3>
          <p>{{ data.fullName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>项目名称</h3>
          <p>{{ data.projectName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>项目编号</h3>
          <p>{{ data.projectNumber || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查日期</h3>
          <p>{{ formatTime(data.inspectionTime) || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查单类型</h3>
          <p>{{ data.checklistType || '-' }}</p>
        </li>
        <li
          :class="$style['spot-detail-infoLists-item']"
          v-if="data.checklistType !== '一般记录'"
        >
          <h3>隐患类型</h3>
          <p>{{ data.dangerType || '-' }}</p>
        </li>
        <li
          :class="$style['spot-detail-infoLists-item']"
          v-if="['隐患整改', '停工整改'].includes(data.checklistType)"
        >
          <h3>检查状态</h3>
          <p>{{ data.inspectionState || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>单位名称</h3>
          <p>{{ data.companyName || '-' }}</p>
        </li>

        <li :class="$style['spot-detail-infoLists-item']">
          <h3>开单对象</h3>
          <p>{{ data.billingObject || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>所属科室</h3>
          <p>{{ data.departmentName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>整改负责人</h3>
          <p>{{ data.rectifierName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>整改期限</h3>
          <p>{{ formatTime(data.rectificationDeadline) || '-' }}</p>
        </li>
      </ul>
      <ul :class="$style['spot-detail-infoLists']">
        <li
          :class="[
            $style['spot-detail-infoLists-item'],
            $style['spot-detail-infoLists-desc'],
          ]"
        >
          <h3>整改内容</h3>
          <p>
            {{ data.rectificationContent || '-' }}
          </p>
        </li>
      </ul>
      <ul :class="$style['spot-detail-infoLists']">
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>检查照片</h3>
          <div :class="$style['spot-detail-infoLists-infoImg']">
            <div v-if="pics1.length">
              <x-oss-image
                v-for="(item, index) in pics1"
                :key="index"
                basePath="/oss/oss"
                :ossPath="item.value || ''"
                @click="
                  openImg(
                    pics1.map(ele => ele.value),
                    index,
                  )
                "
                class="table-img"
              />
            </div>
            <div v-else style="width: 30px;">
              <NoImg />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <WorkerTitle title="整改阶段" />
    <div :class="$style['spot-detail-workerInfo']">
      <ul :class="$style['spot-detail-infoLists']">
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>整改负责人</h3>
          <p>{{ data.rectifierName || '-' }}</p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>整改完成时间</h3>
          <p>{{ formatTime(data.rectificationFinishTime) }}</p>
        </li>
      </ul>
      <ul :class="$style['spot-detail-infoLists']">
        <li
          :class="[
            $style['spot-detail-infoLists-item'],
            $style['spot-detail-infoLists-desc'],
          ]"
        >
          <h3>整改结果反馈</h3>
          <p>
            {{ data.rectificationResult || '-' }}
          </p>
        </li>
      </ul>
      <ul :class="$style['spot-detail-infoLists']">
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>整改后照片</h3>
          <div :class="$style['spot-detail-infoLists-infoImg']">
            <div v-if="data.rectificationPictures.length">
              <x-oss-image
                v-for="(item, index) in data.rectificationPictures"
                :key="index"
                basePath="/oss/oss"
                :ossPath="item.value || ''"
                @click="
                  openImg(
                    data.rectificationPictures.map(ele => ele.value),
                    index,
                  )
                "
                class="table-img"
              />
            </div>
            <div v-else style="width: 30px;">
              <NoImg />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <WorkerTitle title="整改审批" />
    <div :class="$style['spot-detail-workerInfo']">
      <ul :class="$style['spot-detail-infoLists']">
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>整改审批人</h3>
          <p>
            {{ data.reInspectionName || '-' }}
          </p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>审批结果</h3>
          <p>
            {{ data.reInspectionResult || '-' }}
          </p>
        </li>
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>审批时间</h3>
          <p>{{ formatTime(data.reInspectionTime) }}</p>
        </li>
      </ul>
      <ul :class="$style['spot-detail-infoLists']">
        <li
          :class="[
            $style['spot-detail-infoLists-item'],
            $style['spot-detail-infoLists-desc'],
          ]"
        >
          <h3>审批结果反馈</h3>
          <p>
            {{ data.reInspectionFeedback || '-' }}
          </p>
        </li>
      </ul>
      <ul :class="$style['spot-detail-infoLists']">
        <li :class="$style['spot-detail-infoLists-item']">
          <h3>审批照片</h3>
          <div :class="$style['spot-detail-infoLists-infoImg']">
            <div v-if="data.reInspectionPictures.length">
              <x-oss-image
                v-for="(item, index) in data.reInspectionPictures"
                :key="index"
                basePath="/oss/oss"
                :ossPath="item.value || ''"
                @click="
                  openImg(
                    data.reInspectionPictures.map(ele => ele.value),
                    index,
                  )
                "
                class="table-img"
              />
            </div>
            <div v-else style="width: 30px;">
              <NoImg />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import WorkerTitle from '../../people-management/components/worker-tab-title';
import NoImg from '../../people-management/components/noImg.vue';
// import { PublicSize } from '@/views/green-construction/components/utils';
// import { createModal } from '@triascloud/x-components';
import { UploadService } from '@triascloud/services';
import { Preview } from '@triascloud/x-components';
@Component({
  components: { WorkerTitle, NoImg },
})
export default class PeopleDetailsDialog extends Vue {
  @Prop({ type: Object, default: () => {}, required: true })
  data;
  @Prop({ type: String, default: 'danger' })
  dialogType;
  mounted() {}
  async openImg(pictures, index) {
    const basePath = '/oss/oss';
    const customService = new UploadService(basePath);
    const abPath = await customService.getAuth(pictures);
    const imgNameList = [];
    const urls = abPath.map((item, index) => ({
      url: item,
      name: imgNameList[index],
    }));
    Preview.createModal({
      urls,
      index,
    });
  }
  get pics1() {
    return this.data?.dangerPictures
      ? this.data?.dangerPictures
      : this.data.inspectionPictures;
  }
  formatTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD') : '-';
  }
}
</script>
<style lang="less" module>
.spot-detail-container {
  .spot-detail-workerInfo {
    width: 100%;
    .spot-detail-infoLists {
      list-style: none;
      display: flex;
      padding: 0 0 0 0.1rem;
      flex-wrap: wrap;
      .spot-detail-infoLists-item {
        width: 33%;
        margin-bottom: 10px;
        h3 {
          font-size: 0.12rem;
          font-family: Source Han Sans CN-Regular;
          font-weight: 400;
          color: rgba(216, 225, 243, 0.5);
          margin: 0 0 4px 0;
        }
        p {
          font-size: 0.12rem;
          font-family: Source Han Sans CN-Regular;
          font-weight: 400;
          color: rgba(225, 232, 245, 1);
          // line-height: 0.2rem;
          margin: 0;
        }
        .people-detail-infoLists-infoImg {
          width: 1.12rem;
          height: 1.34rem;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 9%,
            rgba(17, 31, 47, 0.5) 43%,
            #071c38 91%,
            #010b18 100%
          );
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .spot-detail-infoLists-desc {
        word-break: break-all;
        max-width: 100%;
      }
    }
  }
  .spot-detail-attendance,
  .spot-detail-training,
  .spot-detail-rewardPunish {
    flex: 1;
    .spot-detail-infoLists {
      list-style: none;
      display: flex;
      margin: 0;
      padding-left: 0.1rem;
      .spot-detail-infoLists-item {
        // flex: 1;
        margin: 0 0.3rem 0 0;
        h3 {
          font-size: 0.12rem;
          font-family: Source Han Sans CN-Regular;
          font-weight: 400;
          color: rgba(216, 225, 243, 0.5);
        }
        p {
          font-size: 0.12rem;
          font-family: Source Han Sans CN-Regular;
          font-weight: 400;
          color: rgba(225, 232, 245, 1);
          // line-height: 0.2rem;
          margin: 0;
        }
      }
    }
  }
}
</style>

<style>
.x-empty-content--label {
  font-size: 12px;
}
</style>
