<template>
  <div :class="$style['people-detail-container']">
    <div>
      <WorkerTitle :title="$t('peopleManagement.workerInformation')" />
      <div :class="$style['people-detail-workerInfo']">
        <div :class="$style['people-detail-left']">
          <div v-if="data.avatar.length" :class="$style['people-detail-lf-av']">
            <x-oss-image
              :class="$style['people-detail-avatar']"
              basePath="/oss/oss"
              :ossPath="data.avatar[0].value"
              @click="
                openImg(
                  data.avatar.map(ele => ele.value),
                  value,
                )
              "
            />
          </div>

          <NoImg v-else />
          <div :class="$style['er-code']">
            <a-tooltip placement="right">
              <template slot="title">
                <XQRCode
                  :src="`${qCodeUrl}${data.formId}/${data.dataId}/edit`"
                />
              </template>
              <XQRCode
                :src="`${qCodeUrl}${data.formId}/${data.dataId}/edit`"
                style="width: 100%"
              />
            </a-tooltip>
          </div>
        </div>
        <div :class="$style['people-detail-right']">
          <ul :class="$style['people-detail-infoLists']">
            <li :class="$style['people-detail-infoLists-item']">
              <h3>姓名</h3>
              <p>{{ data.fullName || '-' }}</p>
            </li>
            <li :class="$style['people-detail-infoLists-item']">
              <h3>年龄</h3>
              <p>{{ data.age && data.age != -1 ? data.age : '-' }}</p>
            </li>
            <li :class="$style['people-detail-infoLists-item']">
              <h3>性别</h3>
              <p>{{ data.sex }}</p>
            </li>
            <li :class="$style['people-detail-infoLists-item']">
              <h3>民族</h3>
              <p>{{ data?.nation || '-' }}</p>
            </li>
          </ul>
          <ul :class="$style['people-detail-infoLists']">
            <li :class="$style['people-detail-infoLists-item']">
              <h3>班组</h3>
              <p>{{ data.teamName || '-' }}</p>
            </li>
            <li :class="$style['people-detail-infoLists-item']">
              <h3>身份证号</h3>
              <p>{{ data.idCard || '-' }}</p>
            </li>
            <li :class="$style['people-detail-infoLists-item']">
              <h3>项目</h3>
              <p>{{ data.projectName || '-' }}</p>
            </li>
            <li :class="$style['people-detail-infoLists-item']">
              <h3>工种</h3>
              <p>{{ data.jobType || '-' }}</p>
            </li>
          </ul>
          <ul :class="$style['people-detail-infoLists']">
            <li :class="$style['people-detail-infoLists-item']">
              <h3>岗位</h3>
              <p>{{ data.jobPosition || '-' }}</p>
            </li>
            <li :class="$style['people-detail-infoLists-item']">
              <h3>籍贯</h3>
              <p>{{ data.nativePlace || '-' }}</p>
            </li>
            <li :class="$style['people-detail-infoLists-item']">
              <h3>健康状况</h3>
              <p>{{ extendDataObj?.health || '-' }}</p>
            </li>
          </ul>
          <template v-if="data.certificates && data.certificates.length">
            <ul
              :class="[
                $style['people-detail-infoLists'],
                $style['people-detail-infoLists-img'],
              ]"
              v-for="(item, index) in data.certificates"
              :key="index"
            >
              <li :class="$style['people-detail-infoLists-item']">
                <h3>证件类型</h3>
                <p>
                  {{ item.certificateType }}
                </p>
              </li>
              <li :class="$style['people-detail-infoLists-item']">
                <h3>特种设备证件号</h3>
                <p>
                  {{ item.certificateNo }}
                </p>
              </li>
              <li :class="$style['people-detail-infoLists-item']">
                <h3>证件图片</h3>
                <div
                  :class="$style['people-detail-infoLists-infoImg']"
                  v-if="item.certificatePictures.length"
                >
                  <x-oss-image
                    :class="$style['people-detail-avatar']"
                    v-for="(sItem, idx) in item.certificatePictures"
                    :key="idx"
                    basePath="/oss/oss"
                    :ossPath="sItem.value"
                    style="height:1.3rem;matgin-top:10px;"
                    @click="
                      openImg(
                        item.certificatePictures.map(ele => ele.value),
                        idx,
                      )
                    "
                  />
                </div>
                <NoImg v-else />
              </li>
            </ul>
          </template>
          <ul
            :class="[
              $style['people-detail-infoLists'],
              $style['people-detail-infoLists-img'],
            ]"
            v-else
          >
            <li :class="$style['people-detail-infoLists-item']">
              <h3>证件类型</h3>
              <p>
                -
              </p>
            </li>
            <li :class="$style['people-detail-infoLists-item']">
              <h3>特种设备证件号</h3>
              <p>
                -
              </p>
            </li>
            <li :class="$style['people-detail-infoLists-item']">
              <h3>证件图片</h3>
              <div :class="$style['people-detail-infoLists-infoImg']">
                <NoImg />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <WorkerTitle :title="$t('peopleManagement.attendanceRecords')">
        <template #action>
          <a href="javascript:;" @click="showWorkTimeDetail">详情</a>
        </template>
      </WorkerTitle>
      <div :class="$style['people-detail-attendance']">
        <ul :class="$style['people-detail-infoLists']">
          <li :class="$style['people-detail-infoLists-item']">
            <h3>最近进出场</h3>
            <p>{{ extendDataObj?.access || '-' }}</p>
          </li>
          <li :class="$style['people-detail-infoLists-item']">
            <h3>最近进出场时间</h3>
            <p>{{ formatTime(extendDataObj?.accessTime) }}</p>
          </li>
        </ul>
      </div>
      <WorkerTitle :title="$t('peopleManagement.trainingRecords')">
        <template #action>
          <a
            href="javascript:;"
            @click="() => showTableDialogRecord('training')"
            >详情</a
          >
        </template>
      </WorkerTitle>
      <div :class="$style['people-detail-training']">
        <ul :class="$style['people-detail-infoLists']">
          <li :class="$style['people-detail-infoLists-item']">
            <h3>累计培训次数</h3>
            <p>{{ extendDataObj?.educationNum || '-' }}</p>
          </li>
          <li :class="$style['people-detail-infoLists-item']">
            <h3>最近培训主题</h3>
            <p>{{ extendDataObj?.educationTopic || '-' }}</p>
          </li>
          <li :class="$style['people-detail-infoLists-item']">
            <h3>最近培训时间</h3>
            <p>{{ formatTime(extendDataObj?.educationTime) }}</p>
          </li>
        </ul>
      </div>
      <WorkerTitle :title="$t('peopleManagement.rewardPunishmentRecord')"
        ><template #action>
          <a href="javascript:;" @click="() => showTableDialogRecord('rp')"
            >详情</a
          >
        </template>
      </WorkerTitle>
      <div :class="$style['people-detail-rewardPunish']">
        <ul :class="$style['people-detail-infoLists']">
          <li :class="$style['people-detail-infoLists-item']">
            <h3>最近奖惩类型</h3>
            <p>{{ extendDataObj?.rp || '-' }}</p>
          </li>
          <li :class="$style['people-detail-infoLists-item']">
            <h3>最近奖惩时间</h3>
            <p>{{ formatTime(extendDataObj?.rpTime) }}</p>
          </li>
        </ul>
      </div>
      <WorkerTitle title="良好行为"
        ><template #action>
          <a @click="() => showTableDialogRecord('good')">详情</a>
        </template>
      </WorkerTitle>
      <div :class="$style['people-detail-rewardPunish']">
        <ul :class="$style['people-detail-infoLists']">
          <li :class="$style['people-detail-infoLists-item']">
            <h3>累计良好行为次数</h3>
            <p>
              {{
                extendDataObj?.goodBehaviorNum === -1
                  ? '-'
                  : extendDataObj?.goodBehaviorNum || '-'
              }}
            </p>
          </li>
          <li :class="$style['people-detail-infoLists-item']">
            <h3>最近良好时间</h3>
            <p>{{ formatTime(extendDataObj?.goodBehaviorTime) }}</p>
          </li>
        </ul>
      </div>
      <WorkerTitle title="不良行为"
        ><template #action>
          <a @click="() => showTableDialogRecord('bad')">详情</a>
        </template>
      </WorkerTitle>
      <div :class="$style['people-detail-rewardPunish']">
        <ul :class="$style['people-detail-infoLists']">
          <li :class="$style['people-detail-infoLists-item']">
            <h3>累计不良行为次数</h3>
            <p>
              {{
                extendDataObj?.badBehaviorNum === -1
                  ? '-'
                  : extendDataObj?.badBehaviorNum || '-'
              }}
            </p>
          </li>
          <li :class="$style['people-detail-infoLists-item']">
            <h3>最近不良时间</h3>
            <p>{{ formatTime(extendDataObj?.badBehaviorTime) }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import WorkerTitle from './worker-tab-title';
import NoImg from './noImg';
import { PublicSize } from '@/views/green-construction/components/utils';
import { createModal, Preview } from '@triascloud/x-components';
import PeopleManageMentDialog from './peopleManageMentDialog.vue';
import { UploadService } from '@triascloud/services';
import { deepClone } from '@triascloud/utils';
import {
  safeEducationDialogColumns,
  workerRewardAndPunishColumns,
  detailGoodBehaveColumns,
  detailBadBehaveColumns,
} from '../../config';
import {
  getWorkerExtend,
  getWorkerPublishRecords,
  getWorkerSafeEducationRecords,
  getLaborGoodBehavior,
  getLaborBadBehavior,
} from '@/services/screen/jiangsu/labor';
import Calendar from './Calendar';
import { getSiteURL } from '@/views/screen/utils';
import XQRCode from '@/components/x-qrcode/index.vue';
import OssImage from '@/views/screen/components/oss-image.vue';

@Component({
  components: { WorkerTitle, NoImg, PeopleManageMentDialog, Calendar, XQRCode },
})
export default class PeopleDetailsDialog extends Vue {
  @Prop({ type: Boolean, default: true }) showForm;
  @Prop({ type: Boolean, default: true }) showAction;
  @Prop({ type: Object, default: () => {} }) data;
  @Prop({ type: String, default: '' }) projectId;

  imgSrc = '';
  avatarSrc = '';
  loading = false;
  showWorkTimeDetail() {
    createModal(
      () => <Calendar data={this.data} projectId={this.projectId} />,
      {
        title: `${this.$t('peopleManagement.attendanceRecords')}`,
        width: PublicSize.LargeLogModalSize,
        closable: true,
        maskClosable: false,
        wrapClassName: 'screen-modal',
      },
    );
  }

  dialogType = 'training';
  dialogTableColumns = this.trainingColumns;
  dialogTitle = '培训记录';
  dialogTableData = [];
  dialogTotal = 0;
  showTableDialogRecord(type) {
    this.dialogType = type;
    this.dialogParmas = Object.assign(
      {},
      this.dialogParmas,
      this.dialogParmasCopy,
    );
    if (type === 'training') {
      this.dialogTableColumns = safeEducationDialogColumns;
      this.dialogTitle = '培训记录';
    } else if (type === 'rp') {
      this.dialogTableColumns = workerRewardAndPunishColumns;
      this.dialogTitle = '奖惩记录';
    } else if (type === 'good') {
      this.dialogTableColumns = detailGoodBehaveColumns;
      this.dialogTitle = '良好行为';
    } else if (type === 'bad') {
      this.dialogTableColumns = detailBadBehaveColumns.concat({
        title: '不良行为图片',
        key: 'behaviorPictures',
        dataIndex: 'behaviorPictures',
        ellipsis: true,
        customRender: text => {
          const imgList = text.map(item => item.value);
          return text.length ? (
            <OssImage basePath="/oss/oss" ossPath={imgList} />
          ) : (
            ''
          );
        },
      });
      this.dialogTitle = '不良行为';
    }
    this.getDialogTableData();
    this.showTrainingRecord();
  }

  showTrainingRecord() {
    createModal(
      () => (
        <PeopleManageMentDialog
          showForm={false}
          showAction={false}
          data={this.dialogTableData}
          columns={this.dialogTableColumns}
          total={this.dialogTotal}
          onChangePagination={this.changePagination}
        />
      ),
      {
        title: this.dialogTitle,
        width: PublicSize.LargeLogModalSize,
        closable: true,
        maskClosable: false,
        wrapClassName: 'screen-modal',
      },
    );
  }

  changePagination(data) {
    this.current = data.current;
    this.size = data.pageSize;
    this.getDialogTableData();
  }
  current = 1;
  size = 10;
  get dialogParmas() {
    return {
      current: this.current,
      dataId: this.data?.dataId,
      projectId: this.projectId,
      size: this.size,
      // type: '',
    };
  }

  dialogParmasCopy = deepClone(this.dialogParmas);

  async getDialogTableData() {
    let res = null;
    switch (this.dialogType) {
      case 'training':
        res = await getWorkerSafeEducationRecords(this.dialogParmas);
        this.dialogTableData = res.records;
        this.dialogTotal = res.total;
        break;
      case 'rp':
        res = await getWorkerPublishRecords(this.dialogParmas);
        this.dialogTableData = res.records;
        this.dialogTotal = res.total;
        break;
      case 'good':
        res = await getLaborGoodBehavior(this.dialogParmas);
        this.dialogTableData = res.records;
        this.dialogTotal = res.total;
        break;
      case 'bad':
        res = await getLaborBadBehavior(this.dialogParmas);
        this.dialogTableData = res.records;
        this.dialogTotal = res.total;
        break;
      default:
        this.dialogTableData = [];
        this.dialogTotal = 0;
        break;
    }
  }

  created() {
    this.getWorkerExtendData();
  }
  extendDataObj = {};
  async getWorkerExtendData() {
    const res = await getWorkerExtend({
      dataId: this.data?.dataId,
    });
    this.extendDataObj = res;
  }
  formatTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '-';
  }

  // formatData(item) {
  //   item['cType'] = item.certificateType;
  //   item['cNo'] = item.certificateNo;
  //   item['cPic'] = item.certificatePictures;
  //   return item;
  // }
  async openImg(pictures, index) {
    const basePath = '/oss/oss';
    const customService = new UploadService(basePath);
    const abPath = await customService.getAuth(pictures);
    const imgNameList = [];
    const urls = abPath.map((item, index) => ({
      url: item,
      name: imgNameList[index],
    }));
    Preview.createModal({
      urls,
      index,
    });
  }
  get qCodeUrl() {
    return getSiteURL();
  }
}
</script>
<style lang="less" module>
.people-detail-container {
  .people-detail-workerInfo {
    display: flex;
    .people-detail-left {
      width: 1.02rem;
      height: 1.28rem;
      background: #223142;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .people-detail-avatar {
        display: block;
        cursor: pointer;
        width: 1.02rem;
        height: 1.28rem;
      }

      .er-code {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0.4rem;
        background: #fff;
        height: 0.4rem;
      }
    }
    .people-detail-right {
      flex: 1;
      .people-detail-infoLists {
        list-style: none;
        display: flex;
        .people-detail-infoLists-item {
          flex: 1;
          flex-shrink: 0;
          max-width: 24%;
          padding-right: 0.02rem;
          h3 {
            font-size: 0.12rem;
            font-family: Source Han Sans CN-Regular;
            font-weight: 400;
            color: rgba(216, 225, 243, 0.5);
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            margin: 0;
          }
          p {
            font-size: 0.12rem;
            font-family: Source Han Sans CN-Regular;
            font-weight: 400;
            color: rgba(225, 232, 245, 1);
            // line-height: 0.2rem;
            margin: 0;
          }
          .people-detail-infoLists-infoImg {
            width: 1.12rem;
            height: 1.34rem;
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 9%,
              rgba(17, 31, 47, 0.5) 43%,
              #071c38 91%,
              #010b18 100%
            );
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .people-detail-infoLists-img {
          display: flex;
          .c-1 {
            flex: 1;
          }
        }
      }
    }
  }
  .people-detail-attendance,
  .people-detail-training,
  .people-detail-rewardPunish {
    flex: 1;
    .people-detail-infoLists {
      list-style: none;
      display: flex;
      margin: 0;
      padding-left: 0.1rem;
      .people-detail-infoLists-item {
        // flex: 1;
        margin: 0 0.3rem 0 0;
        h3 {
          font-size: 0.12rem;
          font-family: Source Han Sans CN-Regular;
          font-weight: 400;
          color: rgba(216, 225, 243, 0.5);
        }
        p {
          font-size: 0.12rem;
          font-family: Source Han Sans CN-Regular;
          font-weight: 400;
          color: rgba(225, 232, 245, 1);
          // line-height: 0.2rem;
          margin: 0;
        }
      }
    }
  }
}
</style>

<style>
.x-empty-content--label {
  font-size: 12px;
}
</style>
