<template>
  <img :src="imageSrc" />
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import QRCode from 'qrcode';

@Component()
export default class XQRCode extends Vue {
  @Prop({ type: String }) src;
  /**
   * @description 8位的十六进制的颜色代码说明：最后两位为透明度
   * https://mubu.com/colla/5tG0azdrMIs
   * @name qrcode的option
   * {
   *  color: {
   *    background: '#00000000', // 生成二维码的backgroundColor（背景色）
   *    foreground: '#010599FF', // 生成二维码的foregroundColor（前景色）
   *  },
   *  margin: 0, // 生成的二维码的外边距
   * }
   */
  @Prop({ type: Object, default: () => {} }) option;
  imageSrc = null;
  @Watch('src', { immediate: true })
  async handleSrcChange(src) {
    this.imageSrc = await QRCode.toDataURL(src, {
      margin: 4,
      ...this.option,
      color: {
        light: this.option?.color ? this.option.color.background : '#ffffffFF',
        dark: this.option?.color ? this.option.color.foreground : '#000000FF',
      },
    });
  }
}
</script>
