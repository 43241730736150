<template>
  <div :class="$style['people-container']">
    <slot name="header"></slot>
    <!-- <a-button @click="showWorkTime">考勤日历=</a-button> -->
    <div v-show="showForm">
      <a-form-model layout="inline" :model="formInline" @submit.native.prevent>
        <a-form-model-item>
          <a-input
            v-model="formInline.keyword"
            :placeholder="$t('peopleManagement.workerName')"
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            v-model="formInline.position"
            :placeholder="$t('peopleManagement.position')"
            style="width: 180px"
          >
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            v-model="formInline.job"
            placeholder="工种"
            style="width: 180px"
          >
            <!-- <a-select-option value="dg">
              电工
            </a-select-option>
            <a-select-option value="mg">
              木工
            </a-select-option> -->
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-table
      :class="$style.table"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      :scroll="{ y: 400, x: 900 }"
      :loading="loading"
      @change="changeTableData"
    >
      <a
        slot="action"
        href="javascript:;"
        slot-scope="text, records"
        v-if="showAction"
        @click="showDetailHandle(records)"
        >{{ isLook ? '查看' : '详情' }}
      </a>
      <div slot="images" slot-scope="text">
        <div v-if="text.url?.length">
          <x-oss-image
            v-for="(item, index) in text.url"
            :key="index"
            basePath="/oss/oss"
            :ossPath="item || ''"
            @click="openImg(text.url, index)"
            class="table-img"
          />
        </div>
        <div v-else style="width: 30px;">
          <NoImg />
        </div>
      </div>
      <div slot="inImg" slot-scope="text">
        <div v-if="text">
          <x-oss-image
            :basePath="isOutAndIn ? '/oss/iot/oss' : '/oss/oss'"
            :ossPath="text"
            @click="openImg([text], index)"
            class="table-img"
          />
        </div>
        <div v-else style="width: 30px;">
          <NoImg />
        </div>
      </div>
      <div slot="dImages" slot-scope="text">
        <div v-if="text?.length">
          <x-oss-image
            v-for="(item, index) in text"
            :key="index"
            basePath="/oss/oss"
            :ossPath="item.value || ''"
            @click="
              openImg(
                text.map(ele => ele.value),
                index,
              )
            "
            class="table-img"
          />
        </div>
        <div v-else style="width: 30px;">
          <NoImg />
        </div>
      </div>
      <div slot="erCode" slot-scope="text, record">
        <div>
          <a-tooltip placement="right">
            <template slot="title">
              <XQRCode
                :src="`${qCodeUrl}${record.formId}/${record.dataId}/edit`"
              />
            </template>
            <XQRCode
              :src="`${qCodeUrl}${record.formId}/${record.dataId}/edit`"
              style="width: 30px;height:30px"
            />
          </a-tooltip>
        </div>
      </div>
    </a-table>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { PublicSize } from '@/views/green-construction/components/utils';
import { createModal, Preview } from '@triascloud/x-components';
import PeopleDetailsDialog from './peopleDetailsDialog';
import SpotCheckedDialog from '@/views/screen/project/spot-checking/components/spotCheckedDialog';
import { UploadService } from '@triascloud/services';
import NoImg from '../../people-management/components/noImg.vue';
import XQRCode from '@/components/x-qrcode/index.vue';
import Calendar from './Calendar';
import { getSiteURL } from '@/views/screen/utils';
import { getLaborDetailById } from '@/services/screen/jiangsu/labor';
@Component({
  components: {
    PeopleDetailsDialog,
    SpotCheckedDialog,
    NoImg,
    XQRCode,
    Calendar,
  },
})
export default class PeopleManageMentDialog extends Vue {
  @Prop({ type: Boolean, default: true }) showForm;
  @Prop({ type: Boolean, default: true }) showAction;
  @Prop({ type: Boolean, default: false }) isLook;
  @Prop({ type: Boolean, default: false }) loading;
  @Prop({ type: String, default: '工人基本信息' }) title;
  @Prop({ type: String, default: 'danger' }) dialogType;
  @Prop({ type: Number, default: 0 }) total;
  @Prop({ type: String, default: '' }) projectId;
  @Prop({
    type: Array,
    default: () => [],
  })
  columns;
  @Prop({ type: Array, default: () => [] }) data;
  isOutAndIn;
  @Prop({ type: Boolean, default: false }) isOutAndIn;

  @Watch('total')
  initTotal() {
    this.pagination.total = this.total;
  }

  mounted() {
    this.pagination.total = this.total;
  }

  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '50'],
    size: 'small',
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };

  changeTableData(pagination) {
    this.pagination = pagination;
    this.$emit('changePagination', pagination);
  }
  formInline = {
    keyword: undefined,
    position: undefined,
    job: undefined,
  };
  async showDetailHandle(data) {
    // window.console.log(data, 'showDetailHandle');
    if (this.isLook) {
      createModal(
        () => <SpotCheckedDialog data={data} dialogType={this.dialogType} />,
        {
          title: this.$t(this.title),
          width: PublicSize.LargeLogModalSize,
          closable: true,
          maskClosable: false,
          wrapClassName: 'screen-modal',
        },
      );
    } else {
      if (this.isOutAndIn) {
        const res = await getLaborDetailById({ dataId: data.dataId });
        // window.console.log(res, 'showDetailHandle');
        Object.assign(data, res);
      }

      createModal(
        () => <PeopleDetailsDialog data={data} projectId={this.projectId} />,
        {
          title: this.$t(this.title),
          width: PublicSize.LargeLogModalSize,
          closable: true,
          maskClosable: false,
          wrapClassName: 'screen-modal',
        },
      );
    }
  }
  async openImg(pictures, index) {
    const basePath = this.isOutAndIn ? '/oss/iot/oss' : '/oss/oss';
    const customService = new UploadService(basePath);
    const abPath = await customService.getAuth(pictures);
    const imgNameList = [];
    const urls = abPath.map((item, index) => ({
      url: item,
      name: imgNameList[index],
    }));
    Preview.createModal({
      urls,
      index,
    });
  }
  get qCodeUrl() {
    return getSiteURL();
  }
}
</script>
<style lang="less" module>
.people-container {
  .table {
    margin-top: 0.1rem;
  }
}
</style>
