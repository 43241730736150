<template>
  <div :class="$style['no-img']">
    <img :src="imgSrc" alt="" />
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import defaultImg from '@/assets/images/screen/default-img.png';
@Component({})
export default class NoImg extends Vue {
  imgSrc = defaultImg;
  created() {}
  mounted() {}
}
</script>
<style lang="less" module>
.no-img {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
}
</style>
